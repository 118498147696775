import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div>
      <p>{`[[[`}<a parentName="p" {...{
          "href": "https://crossfittheville.files.wordpress.com/2011/02/crossfit-man.jpg"
        }}><img parentName="a" {...{
            "src": "https://crossfittheville.files.wordpress.com/2011/02/crossfit-man.jpg?w=225&h=300",
            "alt": null,
            "title": "crossfit-man"
          }}></img>{`{.alignnone
.size-medium .wp-image-37 width=“225”
height=“300”}`}</a><a parentName="p" {...{
          "href": "https://crossfittheville.files.wordpress.com/2011/02/getattachment.jpg"
        }}><img parentName="a" {...{
            "src": "https://crossfittheville.files.wordpress.com/2011/02/getattachment.jpg?w=615",
            "alt": null,
            "title": "GetAttachment"
          }}></img>{`{.alignnone
.size-full
.wp-image-39}`}</a>{`]{style=“font-family: MyriadPro-Light; color: #414142; font-size: x-small;”}]{style=“font-family: MyriadPro-Light; color: #414142; font-size: x-small;”}]{style=“font-family: MyriadPro-Light; color: #414142; font-size: x-small;“} `}</p>
      <p><strong parentName="p">{` Go Outside.`}</strong></p>
      <p><strong parentName="p">{`If there’s one thing I’ve learned during this trans-national`}</strong></p>
      <p><strong parentName="p">{`adventure of mine, it’s that there is nothing like training out`}</strong></p>
      <p><strong parentName="p">{`in the open. Urban environment or rural environment, city`}</strong></p>
      <p><strong parentName="p">{`street or barren trail … get outside and train without walls.`}</strong></p>
      <p><strong parentName="p">{`Some might argue that cities, by their very nature, don’t`}</strong></p>
      <p><strong parentName="p">{`lend themselves to physical exploration. They’re lazy. Some`}</strong></p>
      <p><strong parentName="p">{`say the countryside encourages accessibility, freedom of`}</strong></p>
      <p><strong parentName="p">{`movement and an exploratory eagerness that cities do`}</strong></p>
      <p><strong parentName="p">{`not. Maybe it’s not our environment that cultivates these`}</strong></p>
      <p><strong parentName="p">{`characteristics in us, but we who find them in it. There are`}</strong></p>
      <p><strong parentName="p">{`no objects in nature whose equivalent can’t be found in a`}</strong></p>
      <p><strong parentName="p">{`city if you’re looking with open eyes. Mountains are nature’s`}</strong></p>
      <p><strong parentName="p">{`staircases, and trees are her climbing walls. Carrying a bag`}</strong></p>
      <p><strong parentName="p">{`of cement is the same as shouldering a fallen limb, and`}</strong></p>
      <p><strong parentName="p">{`lifting a stone is, well, you get the picture.`}</strong></p>
      <p>{`[[`}{`[ ]`}{`{style=“font-family: MyriadPro-Light; color: #414142; font-size: x-small;”}]{style=“font-family: MyriadPro-Light; color: #414142; font-size: x-small;”}]{style=“font-family: MyriadPro-Light; color: #414142; font-size: x-small;“}`}<strong parentName="p">{`Taken
from CrossFit Journal.`}</strong></p>
      <p>{`So here is your WOD for today. Take a picture of you doing something
Outside…`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      